import {
    SET_TOKEN,
    SET_USER_DATA,
    SET_SETTING,
    SET_CONTACT_MANAGER,
    SET_NAV,
    SET_THEME,
    SET_UNREAD,
    SET_REFERRAL,
    SET_IB
} from '../constants/user';

const initState = {
    token: null,
    user: null,
    setting: {lang: 'en', dir: 'ltr'},
    contactManager: null,
    nav: false,
    dark: false,
    unread: {},
    referral: {},
    ib: false
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        case SET_SETTING:
            return {
                ...state,
                setting: {
                    lang: action.payload.lang,
                    dir: action.payload.dir
                }
            }
        case SET_CONTACT_MANAGER:
            return {
                ...state,
                contactManager: action.payload
            }
        case SET_NAV:
            return {
                ...state,
                nav: action.payload
            }
        case SET_THEME:
            return {
                ...state,
                dark: action.payload
            }
        case SET_UNREAD:
            return {
                ...state,
                unread: action.payload
            }
        case SET_REFERRAL:
            return {
                ...state,
                referral: action.payload
            }
        case SET_IB:
            return {
                ...state,
                ib: action.payload
            }
        default:
            return state;
    }
}

export default auth